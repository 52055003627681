import cls from "classnames";
import Button from "./Button";

export default function ButtonDark({
  children,
  className,
  disabled,
  loaderColor,
  ...props
}) {
  return (
    <Button
      {...props}
      loaderColor={loaderColor || "#EDF3FE"}
      className={cls(
        "bg-[#434343] hover:bg-opacity-90 text-white",
        !disabled && "hover:brightness-95",
        className
      )}
    >
      {children}
    </Button>
  );
}
