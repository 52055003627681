import cls from "classnames";
import Spinner from "../../../library/loader/Spinner";

// TODO: Define creator theming variable wise like in razorpay

export default function Button(props) {
  const {
    children,
    onClick,
    className,
    doOpacityForDisabled,
    disabled,
    style,
    isLoading = false,
    size = Button.SIZE.large,
    loaderColor,
    ...otherProps
  } = props;

  const variants = {
    [Button.SIZE.tiny]: {
     buttonClass: "h-[1.5rem] px-2 py-1",
     loaderClass: "h-[0.66rem] w-[0.66rem]",
    },
    [Button.SIZE.small]: {
      buttonClass: "h-[2.25rem] px-2 py-1",
      loaderClass: "h-[1.15rem] w-[1.15rem]",
    },
    [Button.SIZE.medium]: {
      buttonClass: "h-[2.5rem] px-3 py-2",
      loaderClass: "h-[1rem] w-[1rem]",
    },
    [Button.SIZE.large]: {
      buttonClass: "h-[3rem] px-3 py-2",
      loaderClass: "h-[1.33rem] w-[1.33rem]",
    }
  };

  const _onClick = () => {
    if (disabled) return;

    onClick && onClick();
  };

  return (
    <button
      className={cls(
        "flex relative text-base items-center justify-center",
        "rounded-lg cursor-pointer font-bold whitespace-nowrap",
        !disabled && "hover:brightness-95",
        disabled && "!cursor-not-allowed",
        disabled && (doOpacityForDisabled ? "opacity-30" : "!bg-[#a9a9a9]"),
        variants[size].buttonClass,
        className
      )}
      disabled={disabled}
      style={style}
      onClick={_onClick}
      {...otherProps}
    >
      {children}
      {isLoading && (
        <Spinner
          color={loaderColor}
          className={cls("ml-2", variants[size].loaderClass)}
        />
      )}
    </button>
  );
}

Button.SIZE = {
  tiny: "tiny",
  large: "large",
  medium: "medium",
  small: "small",
};
