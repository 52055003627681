import cls from "classnames";
import Link from "next/link";

export default function CreatorGuideCard({ image, title, description, link }) {
  return (
    <Link
      className="flex flex-col gap-2 w-[252px] bg-[#F9FAFA] rounded-lg hover:brightness-95"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="w-full object-cover rounded-t-lg bg-[#F3F5FF]"
        src={image}
        alt={title}
      />
      <div className="flex flex-col gap-1 px-4 pb-4">
        <span className="text-lg font-bold text-[#16191B]">{title}</span>
        {description && (
          <span className="text-sm text-[#16191B]">{description}</span>
        )}
      </div>
    </Link>
  );
}
