import cls from "classnames";

export const ClockIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, "stroke-current")}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    ></path>
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M12 6v6l4 2"
    ></path>
  </svg>
);

export const GoogleMeetIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, "stroke-current")}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M23 7l-7 5 7 5V7zM14 5H3a2 2 0 00-2 2v10a2 2 0 002 2h11a2 2 0 002-2V7a2 2 0 00-2-2z"
    ></path>
  </svg>
);

export const GlobeIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, "stroke-current")}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 14.666A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.333zM1.334 8h13.333"
    ></path>
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 1.333A10.2 10.2 0 0110.668 8a10.2 10.2 0 01-2.666 6.666A10.2 10.2 0 015.334 8a10.2 10.2 0 012.667-6.667v0z"
    ></path>
  </svg>
);

export const CalendarIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, "stroke-current")}
    fill="none"
    viewBox="0 0 17 19"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.078 7.17h14.853M12.203 10.424h.007M8.503 10.424h.008M4.8 10.424h.008M12.203 13.664h.007M8.503 13.664h.008M4.8 13.664h.008M11.87 1v2.742M5.136 1v2.742"
    ></path>
    <path
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.032 2.316H4.976C2.529 2.316 1 3.68 1 6.186v7.541c0 2.545 1.529 3.94 3.976 3.94h7.048c2.455 0 3.976-1.371 3.976-3.877V6.186c.008-2.506-1.513-3.87-3.968-3.87z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const ChevronRightIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, "stroke-current")}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 22l-6-6 6-6"
    ></path>
    <rect width="31" height="31" x="0.5" y="0.5" rx="15.5"></rect>
  </svg>
);

export const CrossIcon = ({ className = "w-6 h-6" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, "stroke-current")}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
      d="M12.665 3.333l-9.333 9.333M3.332 3.333l9.333 9.333"
    ></path>
  </svg>
);
