import routes from "routes";
import useSessionStore from "store/sessionStore";
import { getUserOnboardingSteps } from "store/userOnboardingStore/helpers/userOnboarding";
import OnboardingDetailsCard from "components/home/OnboardingDetailsCard";
import InfoCard from "components/home/InfoCard";
import { useRouter } from "next/router";
import QRCodeModal, { useQRCode } from "components/modals/qr_code/QRCode";
import CreatorGuideCard from "components/home/CreatorGuideCard";
import CongratulationsModal, {
  useCongratulations,
} from "components/modals/onboarding/CongratulationsModal";
import { useEffect } from "react";

export default function Home() {
  const router = useRouter();

  const openQRCodeModal = useQRCode((state) => state.openModal);
  const openCongratulationsModal = useCongratulations(
    (state) => state.openModal
  );

  const userData = useSessionStore((state) => state.userData);
  const userOnboardingSteps = getUserOnboardingSteps();

  const showCongratulationsModal =
    userData.early_creator_form_status === "approved" &&
    router?.query?.onboarding === "true";

  const showUserOnboarding =
    !userData.is_creator_onboarding_complete && userOnboardingSteps;

  const showProfileBanner =
    showUserOnboarding &&
    userOnboardingSteps.find((step) => step.id === "user_profile").status !==
      "completed";

  const showPaymentsBanner =
    showUserOnboarding &&
    userOnboardingSteps.find((step) => step.id === "setup_payments").status !==
      "completed";

  const showOnboardingSection = showProfileBanner || showPaymentsBanner;

  function redirectToTelegram() {
    router.push(routes.offerings.route);
  }

  async function handleDownloadApp() {
    openQRCodeModal();
  }

  // show congratulations modal when user fills the onboarding and is redirected to this page
  useEffect(() => {
    if (showCongratulationsModal) {
      openCongratulationsModal();
    }
  }, [showCongratulationsModal]);

  const userDisplayName = userData.name ?? userData.username;

  return (
    <div className="">
      <div className="grid gap-6 grid-cols-1">
        {/* Welcome user! */}
        <span className="text-2xl leading-[48px] font-extrabold text-[#16191B]">
          {userDisplayName ? `Welcome ${userDisplayName}!` : `Welcome`}
        </span>
        {/* Onboarding steps */}
        {showOnboardingSection && (
          <div className="flex flex-wrap gap-5">
            {showProfileBanner && (
              <OnboardingDetailsCard
                image="/assets/images/user.png"
                title={"Profile"}
                description={"Add details to complete your creator profile"}
                buttonText={"Complete profile"}
                link={routes.profile.route}
              />
            )}
            {showPaymentsBanner && (
              <OnboardingDetailsCard
                image="/assets/images/card.svg"
                title={"Payments"}
                description={
                  "Setup your payments to get earnings in your account"
                }
                buttonText={"Setup payments"}
                link={routes.settings_payments.route}
              />
            )}
          </div>
        )}
        {/* Info cards */}
        <div className="flex flex-col gap-5">
          <InfoCard
            image={"/assets/images/telegram.svg"}
            title={"Telegram payments"}
            description={"Launch payments on your Telegram channels and groups"}
            buttonText={"Launch Now"}
            onClick={redirectToTelegram}
            color={InfoCard.prototype.colors.TELEGRAM}
          />
          <InfoCard
            image={"/assets/images/stores.svg"}
            title={"Get the full experience"}
            description={
              "Download the qoohoo app to access all the tools at one place. We are bringing more on the web dashboard very soon!"
            }
            buttonText={"Download App"}
            onClick={handleDownloadApp}
            color={InfoCard.prototype.colors.PRIMARY}
          />
        </div>
        {/* Creator Guides */}
        <div className="flex flex-col gap-2">
          <span className="text-2xl leading-[48px] font-extrabold text-[#16191B]">
            {"Creator guides"}
          </span>
          <span className="text-base text-[#6A7175] mb-4">
            {
              "Learn different ways to monetise on qoohoo and earn from your audience"
            }
          </span>
          <div className="flex flex-wrap gap-6">
            <CreatorGuideCard
              image="https://uploads-ssl.webflow.com/63d83e8abcb37e645894c26f/63fe3bbd87c5186fb0c934a5_monetise%20blog-%20telegram-p-800.png"
              title={"How can you monetize your communities on qoohoo?"}
              // description={"January 31, 2023"}
              link={
                "https://qoohoo.in/blog/how-can-you-monetize-your-communities-on-qoohoo"
              }
            />
            <CreatorGuideCard
              image="https://uploads-ssl.webflow.com/63d83e8abcb37e645894c26f/63fe3b6347a3df8cd5995383_Automate%20telegram%20blog-p-800.png"
              title={"How to launch paid channels on Telegram?"}
              // description={"January 31, 2023"}
              link={
                "https://qoohoo.in/blog/how-to-launch-paid-channels-on-telegram"
              }
            />
            <CreatorGuideCard
              image="https://uploads-ssl.webflow.com/63d83e8abcb37e645894c26f/63da9cce5ad0c8c859e3a717_Earn%20%2410k%20a%20month%20by%20launching%20your%20course-p-800.png"
              title={
                "How creators can earn $10k per month by launching their courses on qoohoo?"
              }
              // description={"January 31, 2023"}
              link={
                "https://qoohoo.in/blog/how-creators-can-earn-10k-per-month-by-launching-their-courses-on-qoohoo"
              }
            />
          </div>
        </div>
      </div>
      <QRCodeModal />
      <CongratulationsModal showCrossButton={false} zeroPadding />
    </div>
  );
}
