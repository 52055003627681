import cls from "classnames";
import SquareButton from "components/settings/SquareButton";
import { useRouter } from "next/router";

export default function OnboardingDetailsCard({
  image,
  title,
  description,
  buttonText,
  link,
}) {

  const router = useRouter();

  const handleButtonClick = () => {
    router.push(link);
  }

  return (
    <div className="bg-[#F9FAFA] rounded-lg p-4 flex flex-col items-center justify-center w-[228px]">
      <img src={image} alt={title} className="w-12" />
      <div className="flex flex-col items-center justify-center my-4 gap-1">
        <span className="text-lg text-[#16191B] font-bold text-center">
          {title}
        </span>
        <span className="text-sm text-[#6A7175] text-center">
          {description}
        </span>
      </div>
      <SquareButton color={SquareButton.colors.PRIMARY} onClick={handleButtonClick}>
        {buttonText}
      </SquareButton>
    </div>
  );
}
