import { useRouter } from "next/router";
import Button, { ButtonPrimary } from "@qoohoo/common/components/library/Button";
import withModal from "hooks/withModal";
import createUseModalStore from "store/createModalStore";
import routes from "routes";

const Congratulations = ({ closeModal }) => {
  const router = useRouter();

  function handleStartCreating() {
    closeModal();

    // removes the query param from the url, without page reload so that
    // if user reloads the page, the modal will not show up again
    router.replace(
      {
        pathname: routes.home.route,
        query: {
          onboarding: true,
        },
      },
      routes.home.route,
      { shallow: true }
    );
  }

  return (
    <div className="sm:inline-block sm:align-middle sm:h-full w-full">
      <div className="flex flex-col items-center justify-center">
        <img
          src="/assets/illustrations/header.svg"
          alt="Congratulations 🎉"
          className="w-full rounded-t"
        />
        <h3 className="text-2xl font-extrabold text-center text-[#16191B] mt-9 mx-4">
          {"Congratulations 🎉"}
        </h3>
        <h3 className="text-base text-center text-[#16191B] my-3 mx-4">
          {
            "You are now a creator on qoohoo.\nLaunch your subscriptions, courses, telegram channels, locked content & more"
          }
        </h3>
        <div className="flex flex-col w-full items-center justify-center gap-4 mt-3 mb-9 hover:brightness-95">
          <ButtonPrimary className="w-full max-w-[220px]" size={Button.SIZE.medium} onClick={handleStartCreating}>
            Start creating
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export const useCongratulations = createUseModalStore("Congratulations");
const CongratulationsModal = withModal(Congratulations, useCongratulations);

export default CongratulationsModal;
