import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import {CrossIcon} from "@qoohoo/web/components/meeting/Icons";
import cls from "classnames";

export default function withModal(ModalContent, useModalStore, title, description) {
  return (props) => {

    const {isOpen, closeModal} = useModalStore(state => state);
    const {onClose, allowOutsideClick = false, showCrossButton = true, zeroPadding = false} = props;

    const handleCloseModal = onClose ? onClose : closeModal;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={allowOutsideClick ? handleCloseModal : () => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {/* Full-screen scrollable container */}
                <div className="fixed inset-0 overflow-y-auto">

                  {/* Container to center the panel */}
                  <div className="flex min-h-full items-center justify-center p-4">

                    {/* The actual dialog panel  */}
                    <Dialog.Panel
                      className={cls("w-full max-h-[600px] max-w-sm transform overflow-auto rounded bg-white text-left align-middle shadow-xl transition-all", zeroPadding ? "p-0" : "p-6")}
                    >
                      {showCrossButton && (<button className="absolute top-4 right-4 text-[#64686B]" onClick={handleCloseModal}>
                        <CrossIcon className="w-5" />
                      </button>)}

                      {
                        title && (
                          <Dialog.Title
                            as="h3"
                            className="text-center text-lg font-bold leading-6 text-gray-900 mb-6"
                          >
                            {title}
                          </Dialog.Title>
                        )
                      }

                      <ModalContent
                        {...props}
                        closeModal={handleCloseModal}
                      />

                    </Dialog.Panel>

                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
}
