import withModal from "hooks/withModal";
import createUseModalStore from "store/createModalStore";

const QRCode = ({ closeModal }) => {
  return (
    <div className="sm:inline-block sm:align-middle w-full h-[384px]">
      <div className="flex flex-col items-center justify-center m-auto h-full">
        <img
          src="/assets/icons/qrcode.svg"
          alt="qoohoo qr code"
          className="h-40"
        />
        <h3 className="text-xl font-bold text-center text-[#16191B] mt-4">
          {"Scan to download"}
        </h3>
      </div>
    </div>
  );
};

export const useQRCode = createUseModalStore("QRCode");
const QRCodeModal = withModal(QRCode, useQRCode);

export default QRCodeModal;
