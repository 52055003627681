import cls from "classnames";
import Button from "@qoohoo/common/components/library/Button";

export default function SquareButton({ children, onClick, disabled, className, color, size, isLoading=false }) {
  return (
    <Button
      className={cls(
        "text-sm !rounded-md",
        !disabled && "hover:brightness-95",
        className,
      )}
      isLoading={isLoading}
      size={size || Button.SIZE.small}
      onClick={onClick}
      disabled={disabled}
      style={{
        border: "1px solid",
        background: color.button.bg,
        borderColor: color.button.border,
        color: color.button.text,
      }}
      doOpacityForDisabled
    >
      {children}
    </Button>
  );
}

SquareButton.colors = {
  PRIMARY: {
    button: {
      border: "#E2E4FC",
      bg: "#EEF0FE",
      text: "#384AF1",
    },
  },
  DISABLED: {
    button: {
      border: "#00000000",
      bg: "#F0F0F0",
      text: "#16191B",
    },
  },
};
