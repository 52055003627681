import cls from "classnames";
import Button from "./Button";

export default function ButtonInvert({children, className, disabled, loaderColor, ...props}) {
    return (
      <Button 
        {...props} 
        disabled={disabled}
        loaderColor={loaderColor || "#384AF1"}
        className={cls(
          "bg-[#EDF3FE] text-[#384AF1]",
          !disabled && "hover:brightness-95",
          className
        )}
      >
        {children}
      </Button>
    )
  }  