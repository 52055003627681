import cls from "classnames";
import Button from "./Button";

export default function ButtonPrimary({children, className, disabled, loaderColor, ...props}) {
    return (
      <Button
        {...props}
        disabled={disabled}
        loaderColor={loaderColor || "#EDF3FE"}
        className={cls(
          "bg-[#384AF1] text-white",
          !disabled && "hover:brightness-95",
          className
        )}
      >
        {children}
      </Button>
    )
  }