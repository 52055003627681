import cls from "classnames";
import SquareButton from "components/settings/SquareButton";
import Button from "@qoohoo/common/components/library/Button";

export default function InfoCard({
  image,
  title,
  description,
  buttonText,
  onClick,
  color,
  disabled,
}) {
  function handleOnClick() {
    onClick();
  }

  return (
    <div
      className="rounded-lg p-4 flex flex-row items-center justify-center w-full max-w-2xl"
      style={{
        background: color.bg,
      }}
    >
      <img src={image} alt={title} className="w-12" />
      <div className="flex flex-col items-start justify-center ml-4 mr-auto">
        <span className="text-lg text-[#16191B] font-bold">{title}</span>
        <span className="text-sm text-[#6A7175]">{description}</span>
      </div>
      <SquareButton className={cls("min-w-24 !w-fit ml-12", disabled && "!opacity-100 !cursor-default")} size={Button.SIZE.small} disabled={disabled} onClick={handleOnClick} color={color}>
        {buttonText}
      </SquareButton>
    </div>
  );
}

InfoCard.prototype.colors = {
  TELEGRAM: {
    bg: "#DEF3FC",
    button: {
      border: "#00000000",
      bg: "#384AF1",
      text: "#FFFFFF",
    },
  },
  PRIMARY: {
    bg: "#F9FAFA",
    button: {
      border: "#E2E4FC",
      bg: "#EEF0FE",
      text: "#384AF1",
    },
  },
  DISABLED: {
    bg: "#F9FAFA",
    button: {
      border: "#00000000",
      bg: "#F0F0F0",
      text: "#16191B",
    },
  },
};
